// import actions
import {
  GET_CLIENTS, GET_CLIENT, CREATE_CLIENT, EDIT_CLIENT,CLEAR_CLIENT,
  ENTER_CLIENT,
  GET_FILES_STAT,
  GET_STATISTIC,
  DROP_COLLECTION,
  GET_FEATURES, SWITCH_FEATURE,
  CLONE_CLIENT, ERASE_CLIENT,
  SEND_TO_TELEGRAM,
  GET_HANGED_FILES,
  DEL_UNUSED_FILES,
  DEL_FILE,
  GET_JOURNAL,
  GET_THANKS,
  EXPORT,
  EXPORT_ALL,
  GET_BDAYS,
  GET_NOTIF_TEMPLATES,
  SAVE_NOTIF_TEMPLATE,
  DEL_NOTIF_TEMPLATE,
} from './actions.type'

// import mutations
import {
  ADD_CLIENT, REMOVE_CLIENT, SET_CLIENTS, SET_CLIENT, SET_CURRENT_CLIENT,
  UPDATE_CLIENT,
  SET_LOGGED_CLIENT,
  SET_STATISTIC,
  SET_FILES_STAT,
  SET_DIALOG_MESSAGE,
  SET_FEATURES,
  SET_FEATURE,
  SET_HANGED_FILES,
  SET_JOURNAL,
  SET_THANKS,
  SET_PROCESSING,
  SET_BDAYS,
  SET_NOTIF_TEMPLATES,
  UPD_NOTIF_TEMPLATE,
  REMOVE_NOTIF_TEMPLATE,
  ADD_JOURNAL_REC,
} from './mutations.type'

// import service functions
import {
  createClient, editClient, getClients, getClient, clearClient,
  changeWorkspace,
  getStatistic,
  getFilesStat,
  deleteCollection,
  getFeatures,
  switchFeature,
  sendTextToTelegram,
  cloneClient, eraseClient,
  getHangedFiles,
  delUnusedFiles,
  delFile,
  getLogsJournal,
  getThanks,
  exportAll,
  exportOne,
  getBirthdays,
  getNotifTemplates,
  saveNotifTemplates,
  deleteNotifTemplate,
} from './client.service';

type Feature = {
  _id?: string,
  feature: string,
  active: Boolean
}

type Thank = {
  _id: string,
  googleImage: string,
  total: number,
  fullName: string,
}


type User = {
  _id: string,
  firstName: string,
  lastName: string,
  birthDate: string,
  googleImage: string | undefined,
}
type BirthdaysRes = {
  today: User[],
  next: {
    format: string,
    users: User[]
  }[]
}
interface Client {
  _id: any,
  workspace: String, // текстовый идентификатор клиента
  code: String,   // Краткий код для удобства опознавания и вывода
  name: String,   // Полное наименование компании
  city: String,   // город компании
  address: String, // Адрес компании
  phone: String,   // основной телефон компании
  director: String, // директор компании
  logo: String,       // путь к логотипу компании
  colors: string[],  // массив корпоративных цветов,
  has_access: Boolean, // статус активного доступа к функционалу
  comment: String,
  features: Feature[], // список всех фич
}

interface NotifTemplate {
  _id: string,
  client_id: string,
  channel: string,
  code: string,
  name: string,
  template: {
    ru: string,
    ua: string,
    en: string,
  }
}

interface State {
  clients: Client[],
  client: Client | undefined,
  currentClient: Client | undefined,
  clientLogged: String,
  client_statistic: Object | null,
  dialog_message: String,
  features: Feature[],
  hangedFiles: Object[],
  journal: Object[],
  thanks?: Thank[],
  processing: Boolean,
  filesStat: Number, // занимаемое место всех файлов
  birthdays: BirthdaysRes | null,
  notif_templates: NotifTemplate[],
  vendor: String,  // yandex/google
}

const store: State = {
  clients: [],
  client: undefined,
  currentClient: undefined,
  clientLogged: "",
  client_statistic: null,
  dialog_message: "",
  features: [],
  hangedFiles: [],
  journal: [],
  thanks: [],
  processing: false,
  filesStat: 0,
  birthdays: null,
  notif_templates: [],
  vendor: '',
}

const getters = {
  clientFeatureFlags(state: State) {
    return state?.currentClient?.features.reduce((acc, f)=> ({...acc, [f.feature]: f.active}), {}) || {}
  },
  apiURL(state: State) {
    return process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : location.origin
  },
  client(state: State) {
    return state.client
  },
  clients(state: State) {
    return state.clients;
  },
  currentClient(state: State) {
    return state.currentClient
  },
  client_statistic(state: State) {
    return state.client_statistic
  },
  dialog_message(state: State) {
    return state.dialog_message
  },
  features(state: State) {
    return state.features
  },
  hangedFiles(state: State) {
    return state.hangedFiles
  },
  journal(state: State) {
    return state.journal
  }
}

const mutations = {
  [SET_DIALOG_MESSAGE](state: State, text: string) {
    state.dialog_message = text
  },
  [SET_CLIENTS](state: State, clients: Client[]) {
    state.clients = clients
  },
  [SET_CLIENT](state: State, client: Client) {
    state.client = client
  },
  [SET_CURRENT_CLIENT](state: State, client: Client | undefined) {
    // console.log(`client ws set to ${client?.name}`);
    state.currentClient = client
  },
  [UPDATE_CLIENT](state: State, client: Client) {
    const index: number = state.clients.findIndex((cl: Client) => cl._id == client._id)
    if (index != -1) {
      state.clients?.splice(index, 1, client)
    }
    if (state.currentClient?._id == client._id) {
      state.currentClient = client
    }
  },
  [ADD_CLIENT](state: State, client: Client) {
    state.clients.push(client)
  },
  [REMOVE_CLIENT](state: State, workspace: string ) {
    state.clients = state.clients.filter(cl => cl.workspace != workspace)
  },
  [SET_LOGGED_CLIENT](state: State, client_id: String) {
    state.clientLogged = client_id
  },
  [SET_STATISTIC](state: State, data: Object) {
    state.client_statistic = data
  },
  [SET_FILES_STAT](state: State, data: Number) {
    state.filesStat = data
  },
  [SET_FEATURES](state: State, data: Feature[]) {
    state.features = data
  },
  [SET_FEATURE](state: State, data: { client_id: string, features: Feature[] }) {
    // ищем клиента и фичу в его фичах
    const client = state.clients.find(c => c._id == data.client_id)
    if (client == undefined) return

    // если фича есть - меняем ее
    client.features = data.features

    if (state.currentClient?._id == client?._id) {
      state.currentClient = client
    }
  
  },
  [SET_HANGED_FILES](state: State, data: Object[]) {
    state.hangedFiles = data
  },
  [SET_JOURNAL](state: State, data: Object[]) {
    state.journal = data
  },
  [ADD_JOURNAL_REC](state: State, data: Object){
    state.journal = [data, ...state.journal]
  },
  [SET_THANKS](state: State, data: Thank[]) {
    state.thanks = data
  },
  [SET_PROCESSING](state: State, data: boolean) {
    state.processing = data
  },
  [SET_BDAYS](state: State, data: BirthdaysRes) {
    state.birthdays = data
  },
  [SET_NOTIF_TEMPLATES](state: State, data: any) {
    state.notif_templates = data
  },
  [UPD_NOTIF_TEMPLATE](state: State, notif_template: NotifTemplate) {
    if (!state.notif_templates.find(t => t.code == notif_template.code)) 
      state.notif_templates.push(notif_template)
    else
      state.notif_templates = state.notif_templates?.map(nt => nt._id == notif_template._id ? notif_template : nt)
  },
  [REMOVE_NOTIF_TEMPLATE](state: State, notif_id: string) {
    state.notif_templates = state.notif_templates.filter(nt => nt._id != notif_id)
  }
}

const actions = {
  [GET_CLIENTS]: getClients,
  [GET_CLIENT]: getClient,
  [CLEAR_CLIENT]: clearClient,
  [CREATE_CLIENT]: createClient,
  [EDIT_CLIENT]: editClient,
  [ENTER_CLIENT]: changeWorkspace,
  [GET_STATISTIC]: getStatistic,
  [GET_FILES_STAT]: getFilesStat,
  [DROP_COLLECTION]: deleteCollection,
  [GET_FEATURES]: getFeatures,
  [SWITCH_FEATURE]: switchFeature,
  [CLONE_CLIENT]: cloneClient,
  [ERASE_CLIENT]: eraseClient,
  [SEND_TO_TELEGRAM]: sendTextToTelegram,
  [GET_HANGED_FILES]: getHangedFiles,
  [DEL_UNUSED_FILES]: delUnusedFiles,
  [DEL_FILE]: delFile,
  [GET_JOURNAL]: getLogsJournal,
  [GET_THANKS]: getThanks,
  [EXPORT]: exportOne,
  [EXPORT_ALL]: exportAll,
  [GET_BDAYS]: getBirthdays,
  [GET_NOTIF_TEMPLATES]: getNotifTemplates,
  [SAVE_NOTIF_TEMPLATE]: saveNotifTemplates,
  [DEL_NOTIF_TEMPLATE]: deleteNotifTemplate,
}

export default {
  state: store,
  getters,
  actions,
  mutations
};