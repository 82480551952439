import Vue from 'vue';
import { AxiosResponse, AxiosError } from 'axios';
import {
  SET_ARTICLE,
  CHANGE_ARTICLES_ORDER,
  SET_ARTICLES,
  SET_CHILDREN,
  REMOVE_ARTICLE,
  REMOVE_VACANCY,
  ARTICLE_ADD_FILES,
  ARTICLE_REMOVE_FILES,
  SET_SERVICE_PAGES,
  SET_VACANCIES, 
  SET_UNUSED_FILES,
  SET_BREADCRUMBS,
  SET_TREE,
  SET_VIEWED,
  SET_RIGHTS,
} from './mutations.type'

import store from "@/store";

// ==== TYPES ====

type UploadResponse = {
  results: FileUploadedInfo[],
  total_size: number
}

// sorting articles by field Order
function sortingByOrder(a:any, b:any) {
  return a.order < b.order ? -1 : (a.order > b.order ? 1 : 0)
}

const config = {
  onUploadProgress: function(progressEvent:any) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    store.state.common.progress = percentCompleted
  }
}

type ArticlesReqParams = { 
  client_id: string, 
  user_id: string, 
  parent?: string, // родительская папка
  search?: string,  // поиск по строке
  fields?: string,  // только нужные поля, через запятую
  full?: boolean, // выдавать все статьи без учета вложенности
  tags?: string[], // список тегов для фильтрации
}
/**
 * Получение статей базы знаний
 */
export const getArticles = async (context: any, params: ArticlesReqParams) => {
  const {client_id, user_id, fields, tags, search, parent, full} = params
  const tagsStr = tags?.length ? '&tags='+tags.join(',') : ''
  const q = `?parent=${parent || ''}&search=${search||''}&full=${full||''}&fields=${fields||''}${tagsStr}`
  const res: AxiosResponse = 
    // await Vue.axios.get(`api/article/client/${params.client_id}/article/${params.parent}`)
    await Vue.axios.get(`api/user/${client_id}/articles/access_for/${user_id}${q}`)
  context.commit(SET_ARTICLES, res.data.result.sort(sortingByOrder))
  return res.data.result
}

/**
 * Получение страниц описаний сервисов
 */
export const getServicePages = async (context: any, params: ArticlesReqParams) => {
  const res: AxiosResponse = 
    await Vue.axios.get(`api/user/${params.client_id}/articles/access_for/${params.user_id}?section=service`)
  context.commit(SET_SERVICE_PAGES, res.data.result)
  return res.data.result
}
/**
 * Получение вакансий
 */
export const getVacancies =async (context: any, params: ArticlesReqParams) => {
  const {client_id, user_id, tags} = params
  const tagsStr = tags?.length ? '&tags='+tags.join(',') : ''
  const res: AxiosResponse = 
    await Vue.axios.get(`api/user/${client_id}/articles/access_for/${user_id}?section=vacancy${tagsStr}`)
  context.commit(SET_VACANCIES, res.data.result)
  return res.data.result
}

export const getBreadcrumbs = async (context: any, id: string) => {
  const res: AxiosResponse = await Vue.axios.get(`api/article/breadcrumbs/${id}`)
  context.commit(SET_BREADCRUMBS, res.data)
  return res.data.result
}

export const getTree = async (context: any, client_id: string) => {
  const res: AxiosResponse = await Vue.axios.get(`api/article/full_tree/${client_id}`)
  context.commit(SET_TREE, res.data)
  return res.data.result
}

/**
 * Получение подчиненных статей для указанной
 */
export const getChildren = (context: any, id: string) => {
  return Vue.axios
    .get(`api/article/child/${id}`)
    .then((res: any) => {
      context.commit(SET_CHILDREN, res.data.result)
    })
}

export const getOneArticle = (context: any, id: string) => {
  return Vue.axios
    .get(`api/article/${id}`)
    .then((res: any) => {
      context.commit(SET_ARTICLE, res.data.result)
    })
}

export const addArticle = async (context: any, article: any) => {
  if (article.formData) {
    if (article.isFile) {
      store.state.common.progress = 0
      store.state.common.progressShow = true
    }
    const  { data } : AxiosResponse<UploadResponse> = await Vue.axios.post(`api/upload`, article.formData, config)
    const { url, thumb } = data.results[0]
    article.url = url
    article.thumb = thumb || ''
    delete article.formData
    if (article.isFile) setTimeout(() => { store.state.common.progressShow = false}, 1500)
  }
  const res: AxiosResponse = await Vue.axios.post(`api/article`, article)
  context.commit(SET_ARTICLE, res.data.result)
  return res.data.result
}

export const delArticle = async (context: any, id: string) => {
  const res: AxiosResponse = await Vue.axios.delete(`api/article/deep/${id}`)
  context.commit(REMOVE_ARTICLE, res.data)
  return res.data
}

export const delVacancy = async (context: any, id: string) => {
  const res: AxiosResponse = await Vue.axios.delete(`api/article/deep/${id}`)
  context.commit(REMOVE_VACANCY, res.data)
  return res.data
}

type FileUploadedInfo = {
  file: {
    name: string, 
    src: string, 
    type: string
  },
  fileName: string,
  location: string,
  thumb: string,
  uploaded: number,
  url: string,
}

export const updArticle = async (context: any, article: any) => {
  if (article.formData) {
    const  { data } : AxiosResponse<UploadResponse> = await Vue.axios.post(`api/upload`, article.formData)
    article.thumb = data.results[0].thumb
    delete article.formData
  }
  const res: AxiosResponse = await Vue.axios.put(`api/article/${article._id}`, article)
  context.commit(SET_ARTICLE, res.data.result)
  return res.data.result
}

// DELETE 
type UploadingData = {
  a_id: string,
  client_id: string,
  files: FormData
}
export const uploadFile = async (context: any, fdata: UploadingData) => {
  try {
    store.state.common.progress = 0
    store.state.common.progressShow = true
    const { data } = await Vue.axios.post('api/upload', fdata.files, config)
    const files = data.results.map(r => ({
        filename: r.file.name,
        filetype: r.file.type,
        path: r.url, //`${process.env.DEFAULT_URL}/public/${newName}`
        thumb: r.thumb,
    }))
    const files_added = await Vue.axios.put(`api/article/${fdata.a_id}/add_files`, {files})
    context.commit(ARTICLE_ADD_FILES, files_added)
    return {
      status: 200,
      result: files_added
    }
  } catch (error) {
    return {
      status: 500,
      error: (error as AxiosError).message
    }
  } finally {
    setTimeout(() => { store.state.common.progressShow = false},1500)
  }
  
  // return Vue.axios.post('api/upload', data.file).then((file_res: any) => {
  //   let file = {
  //     filename: file_res.data.file.name,
  //     filetype: file_res.data.file.type,
  //     path: file_res.data.url //`${process.env.DEFAULT_URL}/public/${newName}`
  //   }
  //   return Vue.axios.put(`api/article/${data.a_id}/add_file`, file).then((art_file_add:any)=>{
  //     return {
  //       status: 200,
  //       result: context.commit(ARTICLE_ADD_FILE, file)
  //     }
  //   })
  // }).catch(err => {
  //   return {
  //     status: 500,
  //     error: err.message
  //   }
  // })
}

export const delFile = async (context: any, data: any) => {
  try {
    await Vue.axios.put(`api/article/${data.article_id}/del_file/${data.file_id}`)
    context.commit(ARTICLE_REMOVE_FILES, [data.file_id])
    return {
      status: 200,
      result: 'ok',
    }
  } catch (error) {
    return {
      status: 500,
      result: "Error on deleting file",
    } 
  }
}

export const delAllFiles = async (context: any, files: {article_id: string, client_id: string}) => {
  // удаляем файлы из базы и получаем их _id
  const { data } = await Vue.axios.put(`api/article/${files.article_id}/del_files`)
  const { status, result } = data
  if (status == 200) {
    console.log('Deleted files', result);
    context.commit(ARTICLE_REMOVE_FILES, result.files)
  } else throw "Error on deleting files from db"
}

export const setSectionToAllArticles = (context: any, wsp: string) => {
  return Vue.axios
    .put('/api/article/empty_as_articles/' + wsp)
    .then((res: any) => {
      console.log(`Статей скорректировано: ${res.count}`);
      return res
    })
}

export const getUnusedFiles = (context: any) => {
  return Vue.axios.get(`/api/article/service/unused_files`).then((result)=>{
    context.commit(SET_UNUSED_FILES, result.data)
  })
}

export const delUnusedFiles = async (context: any, files: string[]) => {
  await Vue.axios.post(`/api/del_files`,{files})
  const result = await Vue.axios.get(`/api/article/service/unused_files`)
  context.commit(SET_UNUSED_FILES, result.data)
}

type changingParams = {
  client_id: string, 
  from: number, 
  to: number
}
export const changeOrder = async (context: any, params: changingParams) => {
  try {
    const res: AxiosResponse = await Vue.axios.put(`/api/article/${params.client_id}/order/${params.from}/${params.to}`)
    // console.log('After reorder: ', ordered.map((e:any) => ({id: e._id, name: e.header, order: e.order})))
    context.commit(CHANGE_ARTICLES_ORDER, res.data.result)
    return res.data.result
  } catch (err) {
    console.error('Error while reordering:', (err as Error).message);
  }
}

type VacRespond = {
  email: string,
  client_id: string,
  id: string,
  name: string,
  text: string,
  formData: FormData | undefined,
  attachments: any[] | undefined,
}
export const vacancyRespond = async (_context:any, params: VacRespond) => {
  try {
    if (params.formData) {
      const response = await Vue.axios.post('api/upload', params.formData, config)
      params.attachments = response.data.results.map((d:any) => d.url) // url = direct link
      delete params.formData
    }
    const res: AxiosResponse = await Vue.axios.post(`/api/article/vac_respond`, params)
    // console.log('After reorder: ', ordered.map((e:any) => ({id: e._id, name: e.header, order: e.order})))
    return res.data.result
  } catch (err) {
    console.error('Error while reordering:', (err as Error).message);
  }
}

// просмотр статьи
export const setViewed = async (ctx: any, params: {id: string, user_id: string} ) => {
  type Resp = {
    article_id: string,
    view: {
      user: string,
      time: Date,
    }
  }
  try {
    const resp: AxiosResponse<Resp> = await Vue.axios.put(`api/article/${params.id}/view/${params.user_id}`)
    ctx.commit(SET_VIEWED, resp.data.view)
    return resp.data
  } catch (error) {
    console.error('Error while view article:', (error as AxiosError).message);
  }
}

// установка прав для статьи
type ArticleRightsData = {
  id: string,
  grants: {
    [K in 'all' | 'all_heads' | 'email' | 'dep']: string | boolean
  }[]
}
export const setRights = async ({commit}: any, data: ArticleRightsData) => {
  const resp: AxiosResponse<ArticleRightsData> = await Vue.axios.put(`api/article/${data.id}/rights`, data.grants)
  commit(SET_RIGHTS, resp.data)
  return resp.data
}